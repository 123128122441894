<template>
  <div class="bg bg4">
    <div class="class--con">
      <class-but @forward="forward"/>
<!--      <img class="title&#45;&#45;img" :src="menu1" alt="">/-->
      <div class="class--con__title">课程培养方向</div>
      <div class="class--container">
        <div>
          <div class="class--con__info">
            <img :src="courseDescList.imgUrl" alt="">
          </div>
        </div>
        <div class="class--con__pictures">
          {{ courseDescList.msg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
// import menu1 from "@/assets/image/conference/btn_grow.png"
// import menu2 from "@/assets/image/conference/pic_right11111111111111.png"
import service from "@/utils/axios";

export default {
  name: "fourPage",
  components: {ClassBut},
  data() {
    return {
      // menu1: menu1,
      // menu2: menu2,
      courseDescList: {}
    }

  },
  created() {
    this.gitApicourseDesc()
  },
  methods: {
    forward() {
      this.$router.push({
        name: 'PageFifth',
        query: this.$route.query,
      })
    },
    async gitApicourseDesc() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/courseDesc`, {
        classId: this.$route.query.classId
      })
      this.courseDescList = res
    },
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  padding: 0px 90px;
  box-sizing: border-box;
  display: flex;
}

.title--img {
  position: absolute;
  left: -41px;
  top: 205px;
}

.class--con__title {
  color: #2B3654;
  font-size: 45px;
  font-weight: bold;
  margin-left: 90px;
  margin-top: 45px;
  margin-bottom: 20px;
}

.class--con__info {
  width: 1143px;
  height: 700px;
  border-radius: 10px;
  border: 3px solid #FFC94D;
  /*background: linear-gradient(180deg, #FC795B 0%, #FFAC6D 100%);*/
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 1133px;
    max-height: 690px;
  }
}

.class--con__pictures {
  padding: 10px;
  width: 459px;
  height: 427px;
  border-radius: 10px;
  border: 4px dashed #FC795B;
  margin-left: 48px;
  overflow-y: auto;
  background: linear-gradient(180deg, #FC795B 0%, #FFAC6D 100%);
  font-size: 24px;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #FFAC6D;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #FC795B;
  border-radius: 10px;
}
</style>
